module.exports = {
  siteTitle: 'Susan Gerlovina | SLP', // <title>
  manifestName: 'Resume',
  manifestShortName: 'Landing', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#009fb2',
  manifestThemeColor: '#0078a8',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/img/website-icon.png',
  pathPrefix: `/gatsby-starter-resume/`, // This path is subpath of your hosting https://domain/portfolio
  firstName: 'Susan',
  lastName: 'Gerlovina',
  // social
  socialLinks: [
    {
      icon: 'fa-linkedin-in',
      name: 'Linkedin',
      url: 'https://linkedin.com/susan-gerlovina-51834a203/',
    },
  ],
  email: 'SGerlovina@gmail.com',
  phone: '347-657-8603',
  address: 'Brooklyn, NY',
};
