import React from 'react';

import Layout from '../components/Layout';

// import { Link } from 'gatsby';
import Sidebar from '../components/Sidebar';
import config from '../../config';

import moses from '../assets/pdf/recommendations/nelson_moses.pdf';
import julia from '../assets/pdf/recommendations/julia.pdf';
import annamarie from '../assets/pdf/recommendations/anna_marie.pdf';
import eval1 from '../assets/pdf/cpe/eval1.pdf';
import eval2 from '../assets/pdf/cpe/eval2.pdf';
import eval3 from '../assets/pdf/cpe/eval3.pdf';
import eval4 from '../assets/pdf/cpe/eval4.pdf';
import evaluation from '../assets/pdf/materials/Evaluation.pdf';
import iep from '../assets/pdf/materials/IEP.pdf';
import managementplan from '../assets/pdf/materials/ManagementPlan.pdf';
import soapnote from '../assets/pdf/materials/soap.pdf';
import speechlanguagereport from '../assets/pdf/materials/SpeechLanguageReport.pdf';
import weeklysessionplan from '../assets/pdf/materials/WeeklySessionPlan.pdf';
import materials from '../assets/pdf/materials/materials.pdf';
// import obs5 from '../assets/pdf/lesson_plans/obs5.pdf';
// import obs6 from '../assets/pdf/lesson_plans/obs6.pdf';
// import obs7 from '../assets/pdf/lesson_plans/obs7.pdf';
// import report1 from '../assets/pdf/sample_reports/report1.pdf';
// import report2 from '../assets/pdf/sample_reports/report2.pdf';
// import report3 from '../assets/pdf/sample_reports/report3.pdf';
// import report4 from '../assets/pdf/sample_reports/report4.pdf';

const IndexPage = () => (
  <Layout>
    <Sidebar />
    <div className="container-fluid p-0">
      <section
        className="resume-section p-3 p-lg-5 d-flex align-items-center"
        id="about"
      >
        <div className="w-100">
          <h1 className="mb-0">
            {config.firstName}
            <span className="text-primary"> {config.lastName}</span>
          </h1>
          <div className="subheading mb-5">
            {config.address} · {config.phone} ·
            <a href={`mailto:${config.email}`}>{config.email}</a>
          </div>
          <p className="lead mb-5">
          Studious bilingual Russian-English Speech-Language Pathologist CF trained in evaluation and treatment of speech and language disorders. Have extensive background in working with children on the autism spectrum. Experienced in AAC devices, treatment of articulation disorders, reading comprehension, auditory comprehension, increasing vocabulary utilizing sight words, improving encoding and decoding skills utilizing phonetic rules and providing support in reaching developmental milestones. Additionally, have a background in behavioral intervention; possessing a board certified assistant behavioral analyst certification.
          </p>
          <div className="social-icons">
            {config.socialLinks.map(social => {
              const { icon, url } = social;
              return (
                <a key={url} href={url}>
                  <i className={`fab ${icon}`}></i>
                </a>
              );
            })}
          </div>
        </div>
      </section>

      <hr className="m-0" />

      <section
        className="resume-section p-3 p-lg-5 d-flex align-items-center"
        id="education"
      >
        <div className="w-100">
          <h2 className="mb-5">Education</h2>

          <div className="resume-item d-flex flex-column flex-md-row justify-content-between mb-5">
            <div className="resume-content">
              <h3 className="mb-0">Long Island University – Brooklyn Campus</h3>
              <div className="subheading mb-3">Masters of Science | Communication Sciences and Disorders</div>
              <ul>
                <li>Certificate for Teaching Students with Speech-Language Disabilities</li>
                <li> Bilingual Extension</li>
              </ul>
            </div>
            <div className="resume-date text-md-right">
              <span className="text-primary">Aug. 2018 - Dec. 2020</span>
            </div>
          </div>

          <div className="resume-item d-flex flex-column flex-md-row justify-content-between">
            <div className="resume-content">
              <h3 className="mb-0">Brooklyn College – Macaulay Honors</h3>
              <div className="subheading mb-3">Bachelor of Arts | Speech Language Pathology/Psychology</div>
              <p></p>
            </div>
            <div className="resume-date text-md-right">
              <span className="text-primary">Aug. 2013 – May 2017</span>
            </div>
          </div>
        </div>
      </section>

      <hr className="m-0" />

      <section
        className="resume-section p-3 p-lg-5 d-flex justify-content-center"
        id="experience"
      >
        <div className="w-100">
          <h2 className="mb-5">Work Experience</h2>

          <div className="resume-item d-flex flex-column flex-md-row justify-content-between mb-5">
            <div className="resume-content">
              <div className="subheading mb-3">Eden II Preschool | Clinical Fellowship</div>
              <ul>
                <li>Conduct non-standardized speech assessment to determine current speech and language levels for preschool and early childhood students on the autism spectrum </li>
                <li>Provide classroom-based services via the collaborative co-teaching model to improve comprehension and participation in the classroom and generalize skills. </li>
                <li>Create PECS books, choice boards, core board and programmed AAC devices to provide a means of communication for non-verbal students</li>
                <li>Focus on increasing verbal production, core words, sight words, MLU and functional communication in daily sessions</li>
                <li>Collaborate with teachers, classroom staff, related providers, and parents to address student goals</li>
              </ul>
            </div>
            <div className="resume-date text-md-right">
              <span className="text-primary">February 2021-Present</span>
            </div>
          </div>

          <div className="resume-item d-flex flex-column flex-md-row justify-content-between mb-5">
            <div className="resume-content">
              <div className="subheading mb-3">A Friendly Face | Board Certified Assistant Behavior Analyst</div>
              <ul>
                <li>Effectively communicate with caregiver concerning the client’s individualized treatment and progress.</li>
                <li>Collaborate with treatment team such as the behavioral technician, parent and school professionals.</li>
                <li>Administer initial and annual assessments.</li>
                <li>Complete initial and concurrent reports detailing client’s current performance and progress.</li>
                <li>Supervise execution of behavioral therapy goals.</li>
                <li>Lead center based social skill groups.</li>
                <li>Train incoming staff on principles of ABA</li>
              </ul>
            </div>
            <div className="resume-date text-md-right">
              <span className="text-primary">May 2019-Present</span>
            </div>
          </div>

          <div className="resume-item d-flex flex-column flex-md-row justify-content-between mb-5">
            <div className="resume-content">
              <div className="subheading mb-3">A Friendly Face | Registered Behavioral Technician</div>
              <ul>
                <li>Implemented behavior analytic services.</li>
                <li>Provided direct client care in 1:1 and group settings utilizing a combination of intensive teaching and natural environment training.</li>
                <li>Collected, recorded, and summarized data on observable and measurable client behavior.</li>
                <li>Maintained a clean, safe, and organized work and therapy environment.</li>
              </ul>
            </div>
            <div className="resume-date text-md-right">
              <span className="text-primary">December 2015-May 2019</span>
            </div>
          </div>

          <h2 className="mb-5">Clinical Practicum</h2>
          <div className="resume-item d-flex flex-column flex-md-row justify-content-between mb-5">
            <div className="resume-content">
              <div className="subheading mb-3">P.S. 282</div>
              <ul>
                <li>Implemented articulation therapy for the /l/, /s/ and /sh/ phonemes to address articulation and lateral lisps. </li>
                <li>Coordinated with teachers and other professionals to lead social skills group for the horizon program </li>
                <li>Implemented language therapy to address literacy rules to improve encoding and decoding skills by utilizing grammar rules </li>
                <li>Attended IEP and weekly meetings </li>
                <li>Provided group therapy to increase pragmatic skills </li>
              </ul>
            </div>
            <div className="resume-date text-md-right">
              <span className="text-primary">Fall 2020</span>
            </div>
          </div>

          <div className="resume-item d-flex flex-column flex-md-row justify-content-between mb-5">
            <div className="resume-content">
              <div className="subheading mb-3">Shoreview Nursing Facility</div>
              <ul>
                <li>Administered bedside evaluations to adults with dementia and post CVA patients</li>
                <li>Treated patients with dysphagia and provided language therapy for post CVA patients</li>
                <li>Performed oral care on NPO patients</li>
                <li>Coordinated with faculty and staff in order to ensure quality and generalization of care</li>
              </ul>
            </div>
            <div className="resume-date text-md-right">
              <span className="text-primary">Summer 2020</span>
            </div>
          </div>

          <div className="resume-item d-flex flex-column flex-md-row justify-content-between mb-5">
            <div className="resume-content">
              <div className="subheading mb-3">Eden II Programs</div>
              <ul>
                <li>Input daily session notes into EZ-Trac System and created progress reports. </li>
                <li>Implemented 1:1 and group therapy to children and adults on the autism spectrum.</li>
                <li>Worked on improving auditory memory, reading comprehension, spelling, navigating AAC devices, inferencing, identifying sight words, increasing MLU and vocabulary in daily therapy sessions</li>
              </ul>
            </div>
            <div className="resume-date text-md-right">
              <span className="text-primary">Spring 2020</span>
            </div>
          </div>

          <div className="resume-item d-flex flex-column flex-md-row justify-content-between mb-5">
            <div className="resume-content">
              <div className="subheading mb-3">Long Island University- Downtown Brooklyn Speech and Hearing Clinic</div>
              <ul>
                <li>Created SOAP notes, weekly session plans, management plans and progress reports for children on the autism spectrum.</li>
                <li>Worked on improving expressive language of beginning sounds and two-word combinations, receptive language and speech production of functional words in 1:1 therapy sessions.</li>
                <li>Created take home activities and updated parents on current goals and progress.</li>
              </ul>
            </div>
            <div className="resume-date text-md-right">
              <span className="text-primary">Fall 2019</span>
            </div>
          </div>
        </div>
      </section>

      <hr className="m-0" />

      <section
        className="resume-section p-3 p-lg-5 d-flex align-items-center"
        id="caw"
      >
        <div className="w-100">
          <h2 className="mb-5">Certifications & Workshops</h2>

          <ul>
            <li>Board Certified Assistant Behavior Analyst (BCaBA)</li>
            <li>Bilingual Education Assessment - Russian on 02/15/20</li>
            <li>Differential Diagnosis in Pre-School Evaluations</li>
            <li>Difference, Disorder or Gap: A School- Age Disability Evaluation</li>
            <li>Culturally and Linguistically Appropriate Evaluations: What Every Evaluator in NYC Needs To Know.</li>
            <li>English Cleft Palate Speech Therapy: Evaluation and Treatment</li>
            <li>Grammar Fundamentals for a Pluralistic Society</li>
          </ul>

        </div>
      </section>

      <hr className="m-0" />

      <section
        className="resume-section p-3 p-lg-5 d-flex align-items-center"
        id="recommendations"
      >
        <div className="w-100">
          <h2 className="mb-5">Recommendations</h2>
          <p className="mb-0">
            Here is some feedback from my supervisors:
            <ol>
              <li>
                <a href={moses}>Nelson Moses, Ph.D, CCC-SLP</a>
              </li>
              <li>
                <a href={julia}>Julia Agranovich M.S., CCC-SLP</a>
              </li>
              <li>
                <a href={annamarie}>Anna Marie Dorelien, MSEd., BCBA, LBA</a>
              </li>
            </ol>
          </p>
        </div>
      </section>

      <hr className="m-0" />

      <section
        className="resume-section p-3 p-lg-5 d-flex align-items-center"
        id="cpe"
      >
        <div className="w-100">
          <h2 className="mb-5">Clinical Practicum Evaluations</h2>
          <ol>
            <li>
              <a href={eval1}>Evaluation 1</a>
            </li>
            <li>
              <a href={eval2}>Evaluation 2</a>
            </li>
            <li>
              <a href={eval3}>Evaluation 3</a>
            </li>
            <li>
              <a href={eval4}>Evaluation 4</a>
            </li>
          </ol>
        </div>
      </section>

      <hr className="m-0" />

      <section
        className="resume-section p-3 p-lg-5 d-flex align-items-center"
        id="samples"
      >
        <div className="w-100">
          <h2 className="mb-5">Samples</h2>
          
          <p className="mb-0">
            Sample works I have prepared:
            <ol>
              <li> <a href={evaluation}> Evaluation </a></li>
              <li> <a href={iep}> Individualized Education Plan</a></li>
              <li> <a href={managementplan}> Management Plan</a></li>
              <li> <a href={soapnote}> SOAP Note</a></li>
              <li> <a href={speechlanguagereport}> Speech Language Report</a></li>
              <li> <a href={weeklysessionplan}> Weekly Session Plan</a></li>
              <li> <a href={materials}> Materials</a></li>
            </ol>
          </p>
        </div>
      </section>
    </div>
  </Layout>
);

export default IndexPage;
